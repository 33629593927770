<template>
  <div class="wenjuan">
    <img :src="bg" v-if="''!=bg" style="width: 100%;" class="wj-bg-top" />
    <div :class="''==bg?'wj-box notbg':'wj-box'">
      <a-list class="wjlist" bordered :data-source="wjData.field.fieldSet">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <h4>#{{ item.id }} {{ item.name }}</h4>
          <a-radio-group>
            <a-radio v-for="op in item.option" :disabled="op.disabled" :key="op.id" :value="op.id" @click="chenkItem(item, op)">
              {{ op.text }}
            </a-radio>
          </a-radio-group>
        </a-list-item>
        <div slot="header">
          <h3> {{ wjData.wenjuan.name }} </h3>
        </div>
      </a-list>
      <div id="SubAnswer" style="text-align:center">
        <a-button type="primary" size="large" icon="cloud" class="subBtn" :disabled="disabledAsy" :loading="iconLoading" @click="SubAnswerBtn()">
          {{ tips.btnText }}
        </a-button>
      </div>
      <a-alert v-if="showAsyBox" style="margin: 20px 0px 10px 5%;width: 90%; position: relative; z-index: 12;" :message='`${tips.scoreMsgText}：${sum}分`' :description="scoreDescText" :type="AsyBoxType" show-icon />
      <a-alert v-if="showAsyBox" :type="AsyBoxType" style="margin: -20px 0px 10px 10%; padding-top: 20px; width: 80%; position: relative; z-index: 11;">
        <template slot="message" style="margin: 20px 0px 10px 5%;width: 90%;">
          <a-badge status="warning" v-for="t in recommend" :text="t" />
        </template>
      </a-alert>

      <a-card v-if="wjData.field.isShow" hoverable style="width: 90%;margin: 40px 0px 0px 5%;border-radius: 10px;overflow: hidden;">
        <img slot="cover" alt="banner" v-if="''!=corp.banner" :src="corp.banner" />
        <template slot="actions" class="ant-card-actions">
          <a-button v-if="''!=corp.wechatUrl" type="primary" icon="wechat" @click="gotoUrl(corp.wechatUrl)">联系客服</a-button>
          <a-button v-if="''!=corp.phone" icon="phone" @click="gotoUrl(`tel://${corp.phone}`)">拨打电话</a-button>
        </template>
        <a-card-meta v-if="''!=corp.name" :title="corp.name" :description="corp.description">
          <a-avatar v-if="''!=corp.logo" slot="avatar" alt="logo" shape="square" :size="64" icon="user" :src="corp.logo" />
        </a-card-meta>
      </a-card>
      <p style="width:100%; font-size: 12px; color:#DFDFDF; height: 18px; text-align:center; margin-top: 20px; padding-bottom: 40px;"> 牙客云 技术支持</p>
    </div>

  </div>
</template>

<script>
import { contactDataApi, openUserInfoApi, contactAnswerApi, contactWenjuanApi } from '@/api/wenjuan'
import { weChatSdkConfig } from '@/api/shopCode'

export default {
  data() {
    return {
      iconLoading: false,
      showAsyBox: false,
      disabledAsy: false,
      url: window.location.href,
      bg: '',
      chenk: [],
      recommend: [],
      chenksum: 0,
      //  用户微信信息
      weChatUserNews: {},
      sum: 0,
      //  客户数据
      wjData: { field: { fieldSet: {} }, wenjuan: { name: '' } },
      corp: {
        banner: '',
        logo: '',
        name: '',
        description: '',
        wechatUrl: '',
        phone: ''
      },
      scoreDescText: '',
      AsyBoxType: 'info',
      tips: {
        btnText: '提交问卷',
        subAnswerErrText: '选项未填完，不能提交！',
        subSuccText: '感谢参与，问卷已保存！',
        scoreMsgText: '根据问卷分析，得分为',
        scoreDescTexts: [{ score: '', compare: '', text: '', color: '' }]
      }
    }
  },
  created() {
    //  获取参数
    this.id = this.$route.query.id
    this.source = this.$route.query.source
    this.getOpenUserInfo()
  },
  methods: {
    chenkItem(item, op) {
      let that=this
      // if (typeof(this.weChatUserNews.unionid) == 'undefined' || 0 == this.weChatUserNews.unionid) {
      //   this.$confirm({
      //   title: '糟糕，我们的快乐被打断了',
      //   okText: '去授权',
      //   cancelText: '再想想',
      //   content: h => <div style="color:red;">请您同意微信授权后再选择！</div>,
      //   onOk() {
      //     that.getOpenUserInfo()
      //   },
      //   onCancel() {
      //     that.$message.error('请同意微信授权后再选择！')
      //   },
      //   class: 'test',
      // });      
      //   return
      // }
      op = JSON.parse(JSON.stringify(op))
      let scored = op.score
      this.chenk[`id${item.id}`] = scored
      if ('' != op.recommend.trim()) this.recommend[`id${item.id}`] = op.recommend
      this.sum = 0
      this.chenksum = 0
      for (const key in this.chenk) {
        this.chenksum++
        this.sum = this.sum + this.chenk[key]
      }
      contactAnswerApi({
        id: this.id,
        union_id: this.weChatUserNews.unionid,
        field_id: item.id,
        data: op.id,
        score: op.score
      }).then((res) => {
        console.log(res)
      })
    },

    SubAnswerBtn() {
      this.iconLoading = true
      let reN = []
      for (const r in this.recommend) {
        reN.push(this.recommend[r])
      }
      this.recommend = reN

      if (this.wjData.wenjuan.sumField > this.chenksum) {
        this.$message.error(this.tips.subAnswerErrText)
        this.iconLoading = false
        return
      }
      contactWenjuanApi({
        id: this.id,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        grade: this.sum
      }).then((res) => {
        this.$message.success(this.tips.subSuccText)

        let SDTs = JSON.parse(JSON.stringify(this.tips.scoreDescTexts))
        console.log(SDTs)
        if (SDTs[0].score != '') {
          let scoreArr = [[], [], []],
            sdtArr = []
          //准备排序数组，按比较方法分类0；小于，1：大于，2：等于
          SDTs.forEach((sdt) => {
            sdtArr['s' + sdt.score] = sdt
            if ('<' == sdt.compare) {
              scoreArr[0].push(sdt.score)
            }
            if ('>' == sdt.compare) {
              scoreArr[1].push(sdt.score)
            }
            if ('=' == sdt.compare) {
              scoreArr[2].push(sdt.score)
            }
          })

          //比较小于
          if (scoreArr[0].length > 0) {
            scoreArr[0].sort(function (a, b) {
              return a - b
            })
            try {
              scoreArr[0].forEach((l) => {
                if (this.sum < l) {
                  this.scoreDescText = sdtArr['s' + l].text
                  this.AsyBoxType = sdtArr['s' + l].color
                  console.log(sdtArr['s' + l].text)
                  throw new Error('最小小于：' + l)
                }
              })
            } catch (e) {
              console.log(e)
            }
          }

          //比较大于
          if ('' == this.scoreDescText && scoreArr[1].length > 0) {
            scoreArr[1].sort(function (a, b) {
              return b - a
            })
            try {
              scoreArr[1].forEach((r) => {
                if (this.sum > r) {
                  this.scoreDescText = sdtArr['s' + r].text
                  this.AsyBoxType = sdtArr['s' + r].color
                  throw new Error('最大大于：' + r)
                }
              })
            } catch (e) {
              console.log(e)
            }
          }

          //比较等于
          if (scoreArr[2].length > 0) {
            try {
              scoreArr[2].forEach((e) => {
                if (this.sum == e) {
                  this.scoreDescText = sdtArr['s' + e].text
                  this.AsyBoxType = sdtArr['s' + e].color
                  throw new Error('等于：' + e)
                }
              })
            } catch (e) {
              console.log(e)
            }
          }
        }

        this.showAsyBox = true
        this.iconLoading = false
        this.disabledAsy = true
        this.tips.btnText = '已完成'
      })
    },
    // 跳转网页
    gotoUrl(url) {
      window.location.href = url
    },
    //获取微信config信息
    getWarrantNews() {
      let that = this
      weChatSdkConfig({
        url: that.url,
        corpId: that.wjData.wenjuan.corpId
      }).then((res) => {
        let that = this
        this.setConfigNews(res.data)
        wx.ready(() => {
          wx.updateAppMessageShareData({
            title: that.wjData.wenjuan.name, // 分享标题
            desc: that.wjData.wenjuan.description, // 分享描述
            link: that.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: that.wjData.wenjuan.templateSet.bg, // 分享图标
            success: function () {
              // 设置成功
            }
          }),
            wx.updateTimelineShareData({
              title: that.wjData.wenjuan.name, // 分享标题
              link: that.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl: that.wjData.wenjuan.templateSet.bg, // 分享图标
              success: function () {
                // 设置成功
              }
            })
        })
      })
    },
    setConfigNews(data) {
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
      })
    },

    getOpenUserInfo() {
      let that = this
      openUserInfoApi({
        id: that.id
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = `/auth/wenjuan?id=${that.id}&target=${encodeURIComponent(that.url)}`
          that.$redirectAuth(redirectUrl)
        }
        this.$message.success('我们可以开始啦！')
        this.weChatUserNews = res.data
        this.getClientData()
      })
    },
    //  获取客户数据
    getClientData() {
      let params = {
        id: this.id,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        avatar: this.weChatUserNews.headimgurl,
        city: this.weChatUserNews.city,
        source: this.source,
        openid: this.weChatUserNews.openid
      }
      contactDataApi(params).then((res) => {
        document.title = res.data.wenjuan.name
        this.wjData = JSON.parse(JSON.stringify(res.data))
        this.corp = res.data.corp
        if (res.data.field.tips != '') {
          if (res.data.field.tips.btnText) {
            this.tips.btnText = res.data.field.tips.btnText
          }
          if (res.data.field.tips.subAnswerErrText) {
            this.tips.subAnswerErrText = res.data.field.tips.subAnswerErrText
          }
          if (res.data.field.tips.subSuccText) {
            this.tips.subSuccText = res.data.field.tips.subSuccText
          }
          if (res.data.field.tips.scoreMsgText) {
            this.tips.scoreMsgText = res.data.field.tips.scoreMsgText
          }
          if (this.wjData.field.tips.scoreDescTexts.length > 0) {
            this.tips.scoreDescTexts = this.wjData.field.tips.scoreDescTexts
          }
        }
        if (this.wjData.wenjuan.templateSet != '') {
          if (this.wjData.wenjuan.templateSet.bg) {
            this.bg = res.data.wenjuan.templateSet.bg
          }
        }
        this.getWarrantNews()
      })
    }
    //处理奖品设置数据
  }
}
</script>

<style scoped lang="less">
.ant-list {
  font-size: 16px !important;
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{padding: 5px 8px;}
.ant-card-small > .ant-card-body {
    padding: 5px 12px;
}
.wj-bg-top {
  width: 100%;
  position: relative;
  z-index: 1;
}
.wj-box {
  position: relative;
  margin-top: -50px;
  z-index: 2;
  font-size: 16px;
  .ant-card-meta-title {
    font-size: 20px !important;
  }
}
.notbg {
  margin-top: 30px !important;
}

.wjlist {
  margin: 10px 5%;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.12);
}

.wjlist h4 {
  width: 100%;
}
.subBtn{
  height: 50px;
  width: 160px;
  border-radius: 30px;
  font-size: 19px;
  box-shadow:2px 2px 7px 0 rgba(0,0,0,0.25);
  margin-top: 10px;
}
.subBtn span{text-shadow: 2px 2px 0px rgba(0, 0, 0 , 0.2);}
</style>