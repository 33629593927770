import {request, request_op} from "../plugins/axios";

export function contactDataApi (params) {
    return request({
        url: '/wenjuan/contactData',
        method: 'post',
        params: params
    })
}


export function contactAnswerApi (params) {
    return request({
        url: '/wenjuan/contactAnswer',
        method: 'put',
        data: params
    })
}

export function contactWenjuanApi (params) {
    return request({
        url: '/wenjuan/contactWenjuan',
        method: 'put',
        data: params
    })
}


export function receiveApi (params) {
    return request({
        url: '/wenjuan/receive',
        method: 'put',
        data: params
    })
}

//获取微信用户信息
export function openUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/wenjuan',
        method: 'GET',
        params: params
    })
}